<template>
  <div class="home-container">
    		    <div >
    		            <iframe
						id="iframe"
    		            src="https://h5.hycolasecjsc.com/"
    		    	    width="100%"
    		    	    :height="scrollHeight"
    		    	    frameborder="0"
    		    	    scrolling="no">
    		            </iframe>
    		        </div>
  				
  		<div class="call-mobile">
  			<a class="" href="tel:0855988888"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0855988888</a>
  		</div>
    </div>
</template>

<script>
export default {
  data() {
    return {
		 scrollHeight: 2600,
      notice: this.$t("获取中"),
      banners: [{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
	getScrollWidthAndHeight() {
	            let iframe = document.getElementById("iframe");
	            let doc = iframe.contentDocument || iframe.document;
	            this.scrollHeight = doc.documentElement.scrollHeight ;
				
				// alert(doc.documentElement.scrollHeight);
	        },
	  
	back(){
	  return window.history.back();
	},
	toServicePage(){
	  this.$router.push("ServicePage");
	}, 
	
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t("刷新成功"));
      }, 500);
    },
	
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
	
	
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {
	  
	  
	  
	// var _this = this
	//         const iframe = document.querySelector('#iframe')
	//         // 处理兼容行问题
	//         if (iframe.attachEvent) {
	//             iframe.attachEvent('onload', function () {
	//                 // iframe加载完毕以后执行操作
	                
	//                 _this.getScrollWidthAndHeight()
	//             })
	//         } else {
	//             iframe.onload = function () {
	//                 // iframe加载完毕以后执行操作
	               
	//                 _this.getScrollWidthAndHeight()
	//             }
	//         }
	    
  },
  created() {
    this.getBasicConfig();
  }
}

</script>
<style src='../../assets/css/sy1.css' scoped></style>
<style src='../../assets/css/sy.css' scoped></style>

<style lang='less' scoped>
	/* @import "../../assets/css/sy.css"; */
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.linear-bg{
  height: 200px;
  background: linear-gradient(270deg,#9e9ce7,#500cfd);
  /* background: linear-gradient(270deg,#e6c3a1,#f905e5); */
}



.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(245, 247, 249);
  /* background-color: #ffffff; */
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 130px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #979799;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #979799;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #f905e5;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #fff;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:335px;
  height:290px;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

.hot-title-div>div:first-child {
    width: 50%;
}


.latest_item {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    margin-bottom: 0.08rem;
    padding: 1rem 1rem 0 1rem;
}

.latest_item .item_name_line {
    position: relative;
    height: 1.6rem;
    line-height: 1.6rem;
}
.latest_item .item_name_line .item_name {
    display: block;
    float: left;
    margin-right: .15rem;
    /* max-width: 4.2rem; */
    height: 1.6rem;
    line-height: 1.6rem;
    text-decoration: none;
    color: #333333;
    font-weight: 500;
}
.font9{font-size:.1888rem}
.fontag{font-size:.22rem}
.font10{font-size:.234rem}
.font12{font-size:.25714285rem}
.font13{font-size:1rem}
.font14{font-size:.3rem}
.font15{font-size:1.3rem}
.font16{font-size:.34285714rem}
.font18{font-size:.38571428rem}
.font20{font-size:.468rem}
.font24{font-size:.56249999rem}
.font28{font-size:.65624999rem}
.substring{overflow:hidden;/* white-space:nowrap; */text-overflow:ellipsis;}
.clear{clear:both;height:0;font-size:0;line-height:0;}
.latest_item .item_name_line .item_wage {
    position: absolute;
    right: 0;
    top: 0;
    height: .6rem;
    line-height: .6rem;
    color: #ffaf24;
    font-weight: 500;
}
.latest_item .item_some_line {
    position: relative;
    height: 1.6rem;
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
}
.latest_item .item_some_line .item_date {
    position: absolute;
    right: 0;
    top: 0;
    height: .6rem;
    line-height: .6rem;
    color: #999999;
}
.latest_item .item_some_line .item_date {
    position: absolute;
    right: 0;
    top: 0;
    height: .6rem;
    line-height: .6rem;
    color: #999999;
}
.latest_item .item_tag {
    padding-bottom: 1rem;
}
.latest_item .item_tag .tag_cell {
    float: left;
    line-height: 1;
    padding: .1rem .2rem;
    margin-right: .15rem;
    font-size: 0.8rem;
    background: #ecf5ff;
    color: #1787fb;
}
.latest_item .split_line {
    width: 100%;
    border-bottom: 1px dashed #f3f3f3;
}
.latest_item .split_line1 {
    width: 100%;
	background-color: #efe9e9;
	height: 3px;
    /* border-bottom: 1px dashed #f3f3f3; */
}
.latest_item .item_company_line {
    position: relative;
    height: 1.9rem;
    line-height: 1.9rem;
}
.latest_item .item_company_line .company_name {
    display: block;
    float: left;
    margin-right: .15rem;
    /* max-width: 5.5rem; */
    height: 1.9rem;
    line-height: 1.9rem;
    text-decoration: none;
    color: #666666;
}
.latest_item .item_company_line .company_auth {
    display: block;
    float: left;
    width: 1.29rem;
    height: 1.9rem;
    background: url("/img/new/company_auth.png") 0 center no-repeat;
    background-size: 1.29rem;
    margin-right: .1rem;
}
.latest_jobs {
    width: 100%;
    margin-bottom: .15rem;
}
/* *{ font-family:"Microsoft YaHei",\5FAE\8F6F\96C5\9ED1,"微软雅黑";font-size:12px;} */
.sssb{
	max-width: 27.2rem;
	    height: 1.6rem;
}

.call-mobile {
    background: #e5332d;
    position: fixed;
    bottom: 70PX;
    height: 40PX;
    line-height: 40PX;
    padding: 0 20PX 0 0PX;
    border-radius: 40PX;
    color: #fff;
    left: 10PX;
    z-index: 6;
    width: 200PX;
    text-align: center;
}
.call-mobile a {
    color: #fff;
    font-size: 18PX;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
}
.call-mobile img {
    background-color: #ad0202;
    border-radius: 39PX;
    float: left;
    width: 39PX;
    height: 39PX;
    margin-right: 10PX;
}
.call-mobiley {

    position: fixed;
    bottom: 70PX;
    height: 40PX;
    line-height: 40PX;
    padding: 0 20PX 0 0PX;
    border-radius: 40PX;
    color: #fff;
    left: 300PX;
    z-index: 6;
    width: 0PX;
    text-align: center;
}
.call-mobiley a {
    color: #fff;
    font-size: 18PX;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
}
.call-mobiley img {
    background-color: #ffffff;
    border-radius: 39PX;
    float: left;
    width: 45PX;
    height: 45PX;
    margin-right: 10PX;
}

</style>
